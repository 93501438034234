var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "listWrap",
      staticClass: "art-list-wrap",
      on: { scroll: _vm.onScroll }
    },
    [
      _vm.isLoading
        ? _c(
            "div",
            [
              _c(
                "center",
                { attrs: { id: "loadingbox" } },
                [_c("van-loading", { attrs: { size: "70px" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "van-pull-refresh",
            {
              attrs: { "success-text": "刷新成功" },
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "van-cell",
                    {
                      key: item.id,
                      staticClass: "box",
                      on: {
                        click: function($event) {
                          return _vm.ToNewsContent(item)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "image" },
                        [
                          _c("van-image", {
                            staticClass: "img",
                            attrs: { fit: "cover", src: item.banner[0].url }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "centext" }, [
                        _c("h3", { staticClass: "van-multi-ellipsis--l2" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _c("p", [
                          _vm._v(_vm._s(_vm._f("formatDate_")(item.created)))
                        ])
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }