<template>
  <div class="art-list-wrap"  ref="listWrap" @scroll="onScroll">
    <div v-if="isLoading">
      <center id="loadingbox">
        <van-loading  size="70px" />
      </center>
    </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="!isLoading" success-text="刷新成功">
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
               <van-cell class="box" v-for="(item) in list" :key="item.id" @click="ToNewsContent(item)">
                   <div class="image">
                        <van-image
                          fit="cover"
                          :src="item.banner[0].url"
                          class="img"
                         />
                   </div>
                    <div class="centext">
                            <h3 class="van-multi-ellipsis--l2">{{item.title}}</h3>
                            <p>{{item.created | formatDate_}}</p>
                          
                    </div>
              </van-cell> 
          </van-list>
      </van-pull-refresh>
  </div>
</template>

<script>
import *as api from'@/utils/api.js'
import { Toast } from 'vant';
export default {
  data() {
    return {
      // 新闻数据
      list: [],
      // 列表加载
      loading: false,
      finished: false,
      refreshing: false,
      // 滚动条距离
      scrollTop:0,
      //  数据加载完毕之前的菊花
      isLoading:true,
      page:0,
      size:8
    };
  },
  activated(){
        // 当组件重新被激活时，就把记录的滚动条位置重新设置给滚动条
    this.$refs.listWrap.scrollTop = this.scrollTop
  },
  methods: {
    // 点击新闻事件
    ToNewsContent(item){
      console.log(item);
      localStorage.setItem('newContent',JSON.stringify(item))
      this.$router.push({
        name:'newsContent',
      })
    },
    // 滚动触发的事件
    onScroll(){
        // 一边滚动一边记录当前滚出去的距离
      // console.log( this.$refs.listWrap.scrollTop )
      this.scrollTop = this.$refs.listWrap.scrollTop 
    },
    onLoad() { 
            setTimeout(async() => {
                let res = await api.getNewsList({
                    page:this.page,
                    size:this.size,
                   })
                // 如果加载完数据了 last为true
                // 把上一段 number赋值给page
                this.page = res.number +1
                
                // 把数组里的每一项一一铺开添加
                this.list.push(...res.content)
                // console.log(this.list);
                // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
                // 这样才可以继续下次加载
                this.loading = false;
                if (res.last == true) {
                    this.finished = true;
                }
            },1000)
                   
        },
async   onRefresh() {
                     this.finished = false;
                //请求最新的数据
                let res =  await api.getNewsList({
                    page:0,
                    size:this.size})
                    this.page = res.number +1
                    console.log(res);
                    this.list = res.content
                    this.refreshing = false
                    this.loading = true
                    this.onLoad()
        },
  },
   mounted(){
        this.$nextTick(()=>{
           this.isLoading = false
        })
 }
}
</script>

<style lang="scss" scoped>
#loadingbox {
  margin-top: 150px;
}
 .art-list-wrap{
  // 弹性高度
  position: fixed;
  // 定位后，div就不会宽度铺满
  // 所以要自己指定好宽度
  // viewport width（视口宽度）
  width: 100vw;
  left:0;
  // top和bottom是为了拉出高度
  top: 90px;
  bottom: 100px;
  // 超出有滚动条
  overflow:auto;
    .van-cell{
        padding:20px 0 0 20px;
        height: 162px;
        
        .van-cell__value {
            display: flex;
            .image{
                .img{
                    width: 180px;
                    height: 120px;
                }
            }
            .centext{
                height: 120px;
                width: 100%;
                padding: 0 30px 0 20px ;
                position: relative;
                h3{
                    width: 100%;
                    font-size: 28px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 600;
                    color: #1A1A1A;
                    line-height: 40px;
                }
                p{
                    text-align: right;
                    font-size: 24px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    position: absolute;
                    bottom: 0;
                    right: 44px;
                }
            }
        }
    }
    .van-cell:after {
    position: absolute;
    content: " ";
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
    }

}
::v-deep{.van-list__finished-text{
  font-size: 28px;
}}


</style>